import React from 'react';
import Layout from '../containers/layout/layout';
import Header from '../containers/layout/header';
import Footer from '../containers/layout/footer';
import SuccessArea from '../containers/success';
import InstagramWrap from '../containers/global/instagram';

const NotFoundPage = () => (
  <Layout>
    <Header />
    <div className="main-content">
      <SuccessArea />
      <InstagramWrap />
    </div>
    <Footer />
  </Layout>
);

export default NotFoundPage;
