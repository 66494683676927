import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Emoji from '../../assets/img/icons/emoji-icon-success.png';
import Heading from '../../components/shared/heading';
import { Link } from 'gatsby';
import {
  SuccessWrap,
  SuccessContent,
  SuccessTopText,
  SuccessBottomText
} from './success.stc';

const SuccessArea = ({ headingCSS, btnCSS }) => {
  return (
    <SuccessWrap>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <SuccessContent>
              <SuccessTopText>
                <Heading as="h1" className="error-404">
                  Brawo
                </Heading>
              </SuccessTopText>
              <SuccessBottomText>
                <img src={Emoji} alt="Sad Emoji" />
                <Heading {...headingCSS}>Wiadomość wysłana poprawnie!</Heading>
                <Link to="/" {...btnCSS}>
                  Powrót do strony głównej
                </Link>
              </SuccessBottomText>
            </SuccessContent>
          </Col>
        </Row>
      </Container>
    </SuccessWrap>
  );
};

SuccessArea.defaultProps = {
  headingCSS: {
    fontSize: ['24px', '28px', '38px', '42px'],
    color: 'secondaryColor',
    mt: '23px',
    mb: '29px'
  },
  btnCSS: {
    btnsize: 'large',
    varient: 'contained',
    shape: 'rounded',
    iconname: 'chevron-right',
    ml: 'auto',
    mr: 'auto'
  }
};

export default SuccessArea;
